.ui-hero {
    margin-bottom: 80px !important;
}

.section-pages-text-area {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-pages-text-area .header {
    font-size: var(--font-size-h3);
    padding-bottom: 20px;
}

.section-pages-text-area .content {
    max-width: 600px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .ui-hero {
        margin-bottom: 40px !important;
    }

    .section-pages-text-area {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .section-pages-text-area .header {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 20px;
    }

    .section-pages-text-area .content {
        margin-left: 15px;
        margin-right: 15px;
        max-width: initial;
        text-align: left;
    }
} 

